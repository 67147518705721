import React from "react";
import Markdown from "markdown-to-jsx";

import ActivityCardDesktop from "../../../components/ActivityCard/ActivityCardDesktop/ActivityCardDesktop";
import Heading from "../../../shared/Heading/Heading";
import InformationText from "../../../shared/InformationText/InformationText";
import Button from "../../../shared/Button/Button";
import ActivitiesLogic from "../ActivitiesLogic";
import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import { goToTheTop } from "../../../helpingFunctions/linkChange";

import styles from "./activities.module.css";

const ActivitesDesktop = ({ setView, showType }) => {
  const { objects, isLoading, pageTextData } = ActivitiesLogic(showType);

  return (
    <>
      <div className={styles.activitiesWrapper}>
        {pageTextData?.InfoBannerText && (
          <div className={styles.activitiesInfoBanner}>
            <Markdown options={{
            overrides: {
              a: {
                component: ({ children, ...props }) => (
                  <a {...props} target="_blank" rel="noreferrer">
                    {children}
                  </a>
                ),
              }
            }
          }}>{pageTextData.InfoBannerText}</Markdown>
          </div>
        )}
        <div className={styles.activitiesHeadline}>
          <Heading
            txt={pageTextData?.DesktopTitle}
            paddingTop={"40px"}
            paddingBottom={"20px"}
            fontSize={"24px"}
            fontFamily="Georgia"
            textTransform="uppercase"
          />
          <div className={styles.information}>
            <InformationText
              content={
                pageTextData?.DesktopDescription_max_length_is_340 ? (
                  <Markdown>
                    {pageTextData?.DesktopDescription_max_length_is_340}
                  </Markdown>
                ) : null
              }
              lineHeight={"1.5rem"}
            />
          </div>
          {isLoading ? (
            <LoadingContainer />
          ) : (
            <section className={styles.activitesDisplay}>
              {objects?.map((object) => (
                <ActivityCardDesktop
                  key={object.id}
                  {...object}
                  onClick={setView.bind(this, {
                    type: object.objType,
                    id: object.id,
                  })}
                />
              ))}
            </section>
          )}
        </div>
        <div className={styles.acitivityFooter}>
          <Button
            txt={"Back to the top"}
            backgroundColor={"var(--color-secondary)"}
            color={"var(--color-primary)"}
            border={"2px solid var(--color-primary)"}
            onClick={goToTheTop}
          />
        </div>
      </div>
    </>
  );
};

export default ActivitesDesktop;
